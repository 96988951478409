import { FC, useCallback, useMemo } from 'react';
import {
  calculateCountOfPages,
  IOnFetchArguments,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetNotificationsQuery } from '~/api';
import { TNotifications } from '~/api/notifications/notifications.types';
import { RefreshButton } from '~/components/RefreshButton';
import { OLD_CRM_ROOT_PATH } from '~/router';
import { linkAndClick } from '~/utils/linkAndClick';
import {
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
  getTableId,
} from '~/utils/table';

import { DISPLAYED_COLUMNS_KEYS, getColumns } from './Notifications.constants';

export const Notifications: FC = () => {
  const [fetchNotifications] = useLazyGetNotificationsQuery();

  const tableId = getTableId('notifications');

  const getNotifications = useCallback(async (params: IOnFetchArguments) => {
    const paginationParams = getPaginationParams(params);
    const filterParams = getFilterParams(params);

    const response = await fetchNotifications({
      ...paginationParams,
      ...filterParams,
    });

    return response.data;
  }, []);

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: {
        onFetch: getNotifications,
      },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
    }),
    [getNotifications],
  );

  const { data, limit, setLimit, setPage, page, skip, isLoading, fetchData } =
    useTableData(tableDataArgs);

  const total = data?.iTotalDisplayRecords || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const columns = getColumns();

  const displayedColumnKeys = DISPLAYED_COLUMNS_KEYS;

  const handleRowClick = ({ pk }: TNotifications) => {
    const notificationUrl = `${OLD_CRM_ROOT_PATH}/notification-${pk}`;

    linkAndClick(notificationUrl);
  };

  const additionalActions = [
    {
      component: (
        <RefreshButton
          onRefresh={fetchData}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
  ];

  return (
    <Table<TNotifications>
      title="Notifications"
      className="NotificationsTable"
      titleSize={1}
      columns={columns}
      displayedColumnKeys={displayedColumnKeys}
      isLoading={isLoading}
      isFlexLayout
      disableSortBy
      manualSortBy
      data={data?.results || []}
      tableId={tableId}
      hasPagination
      showTableInfo
      isHiddenColumnSelect
      saveColumnOrder
      serverPaginationProps={serverPaginationProps}
      saveViewParamsAfterLeave
      handleRowClick={handleRowClick}
      additionalActions={additionalActions}
    />
  );
};
