import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import qs from 'qs';

import { ACCESS_TOKEN_COOKIE_KEY } from '~/constants/session';

import { APP_INSTANCE } from '../getCrmInstanceName';
import { removeEmptyStrings } from '../removeEmptyStrings';

import { TAccessToken, TErrorWithResponse } from './types';

const DEFAULT_API_CRM_HOST = 'crm-backend-stage.exante.eu';
const DEFAULT_API_CPRM_HOST = 'crm-backend-stage.exante.eu';

const DEFAULT_API_HOST =
  APP_INSTANCE === 'cprm' ? DEFAULT_API_CPRM_HOST : DEFAULT_API_CRM_HOST;

const isCrmInsideRun = () =>
  window.runUIhistoryContainer && APP_INSTANCE === 'crm';

const isCprmInsideRun = () =>
  window.runUIhistoryContainer && APP_INSTANCE === 'cprm';

export function getDefaultHeaders(accessToken = '') {
  const baseHeaders: { 'Content-Type': string } = {
    'Content-Type': 'application/json',
  };

  if (accessToken) {
    try {
      const { access_token: token }: TAccessToken = JSON.parse(accessToken);
      return {
        ...baseHeaders,
        Authorization: `Bearer ${token}`,
      };
    } catch (error) {
      console.error(`Access token error: ${error}`);
    }
  }

  return baseHeaders;
}

export function paramsSerializer(params: Record<string, unknown>) {
  const paramsWithoutEmptyStrings = removeEmptyStrings(params);

  return qs.stringify(paramsWithoutEmptyStrings, {
    arrayFormat: 'comma',
    skipNulls: true,
  });
}

export const isAxiosError = (err: unknown): err is AxiosError => {
  return Boolean(get(err, 'isAxiosError'));
};

const getWarnMsg = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] WEB-BO application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getBaseCrmUrl = () => {
  if (isCrmInsideRun()) {
    const url = window.CRM_UI_REACT?.api_urls?.CRM;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseCrmUrl',
          'window.CRM_UI_REACT.api_urls.CRM',
          'process.env.REACT_APP_API',
        ),
      );
      return process.env.REACT_APP_API || DEFAULT_API_CRM_HOST;
    }

    return url;
  }

  if (isCprmInsideRun()) {
    const url = window.CPRM_UI_REACT?.api_urls?.CPRM;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseCrmUrl',
          'window.CPRM_UI_REACT.api_urls.CPRM',
          'process.env.REACT_APP_API',
        ),
      );
      return process.env.REACT_APP_API || DEFAULT_API_CPRM_HOST;
    }

    return url;
  }

  return process.env.REACT_APP_API || DEFAULT_API_HOST;
};

export function getAPIHost() {
  return `https://${getBaseCrmUrl()}`;
}

export const getAccessTokenFromCookies = () =>
  Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

function isErrorWithResponse(error: unknown): error is TErrorWithResponse {
  return (error as TErrorWithResponse).response !== undefined;
}

export function check403Error(error: unknown) {
  return (
    isErrorWithResponse(error) &&
    error?.response &&
    error?.response.status === 403
  );
}

// here we can handle any errors from server
export const handleError = (err: unknown): string => {
  if (isAxiosError(err)) {
    if (err.response?.data?.detail) {
      return err.response?.data?.detail;
    }
    if (err.response?.data) {
      return JSON.stringify(err.response?.data);
    }
  }

  return String(err);
};
