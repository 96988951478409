import { FC, useEffect } from 'react';
import { FileUpload, TFile, UploadStatus } from 'react-ui-kit-exante';

import {
  useUploadFilesMutation,
  useDeleteFileMutation,
  useLazyGetWithdrawalDownloadUploadedFileQuery,
} from '~/api';
import { ACCEPT_FILE_LIST } from '~/constants/files';

import { TUploader } from './Uploader.types';

export const FilesUploader: FC<TUploader> = ({ withdrawal, isLoading }) => {
  const [onUploadFiles, stateUpload] = useUploadFilesMutation();
  const [onDeleteFile, stateDelete] = useDeleteFileMutation();
  const [downloadUploadedFile, downloadState] =
    useLazyGetWithdrawalDownloadUploadedFileQuery();

  const isUninitializedActionsState =
    !stateUpload.isUninitialized || !stateDelete.isUninitialized;

  const isShowLoading =
    (isLoading ||
      stateUpload.isLoading ||
      stateDelete.isLoading ||
      downloadState.isLoading ||
      downloadState.isFetching) &&
    isUninitializedActionsState;

  const resetActionsState = () => {
    if (isUninitializedActionsState && !isShowLoading) {
      stateUpload.reset();
      stateDelete.reset();
    }
  };

  useEffect(() => {
    resetActionsState();
  }, [stateUpload.isUninitialized, stateDelete.isUninitialized, isShowLoading]);

  const filesInitial: TFile[] = withdrawal.uploads.map((file) => ({
    ...file,
    id: String(file.id),
    removable: true,
    size: 0,
    uploadStatus: UploadStatus.SUCCESS,
  }));

  const handleFileAppend = (payload: File[]) =>
    Promise.all(
      payload.map(async (file) => {
        const uploaded = await onUploadFiles({ id: withdrawal.id, file });
        if (!('error' in uploaded)) {
          return uploaded.data.id;
        }
        return null;
      }),
    );

  const handleDelete = async (id: string) => {
    await onDeleteFile({ withdrawalId: withdrawal.id, fileId: id });
  };

  const getFile = async (id: string) => {
    const { data: dataUrl } = await downloadUploadedFile({ id });

    const url = dataUrl?.url;

    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <div>
      {isShowLoading && <FileUpload loading />}
      {!isShowLoading && (
        <FileUpload
          showFileSize={false}
          accept={ACCEPT_FILE_LIST}
          onUpload={handleFileAppend}
          onDelete={handleDelete}
          onDownload={getFile}
          filesInitial={filesInitial}
          withDeleteConfirmation
        />
      )}
    </div>
  );
};
