export enum EReportStatus {
  Done = 'DONE',
  Failure = 'FAILURE',
  Started = 'STARTED',
  Pending = 'PENDING',
}

export enum ETaskState {
  Done = 'DONE',
  Started = 'STARTED',
  Failure = 'FAILURE',
  Success = 'SUCCESS',
  Pending = 'PENDING',
}

export type TReportsGetColumns = {
  refetchData: VoidFunction;
};

export enum EReportType {
  Recipients = 'Recipients',
  Applications = 'Applications',
  Withdrawals = 'Withdrawals',
  Monitoring = 'Monitoring Requests',
  Datachange = 'Data Change Requests',
  Comments = 'Comments',
  Clients = 'Clients',
  ClientsCompliance = 'Clients Compliance',
}
