import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  calculateCountOfPages,
  IOnFetchArguments,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetReportsQuery } from '~/api';
import { TReports } from '~/api/reports/reports.types';
import { RefreshButton } from '~/components/RefreshButton';
import {
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
  getTableId,
} from '~/utils/table';

import {
  DISPLAYED_COLUMNS_KEYS,
  REFETCH_DATA_INTERVAL,
  getColumns,
} from './Reports.constants';
import { getAdditionalFilters, prepareReportsFilters } from './Reports.filters';
import { findTasksInProcess } from './Reports.helpers';

export const Reports: FC = () => {
  const [silentUpdate, setSilentUpdate] = useState<boolean>(false);

  const [fetchReports, { isFetching, isUninitialized }] =
    useLazyGetReportsQuery();

  const tableId = getTableId('reports');

  const getReports = useCallback(async (params: IOnFetchArguments) => {
    const paginationParams = getPaginationParams(params);
    const filterParams = prepareReportsFilters(getFilterParams(params));

    const response = await fetchReports({
      paginationParams,
      filterParams,
    });

    return response.data;
  }, []);

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: {
        onFetch: getReports,
      },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
    }),
    [getReports],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading: isTableLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
  } = useTableData(tableDataArgs);

  const total = data?.iTotalDisplayRecords || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const refetchData = useCallback(() => {
    if (isTableLoading || isFetching) {
      return;
    }

    setSilentUpdate(true);
    fetchData().finally(() => setSilentUpdate(false));
  }, [isTableLoading, isFetching]);

  const columns = useMemo(() => {
    return getColumns({
      refetchData,
    });
  }, [refetchData]);

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [setFilter, removeFilter],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
    }),
    [additionalFilters, resetFilters, filters],
  );

  const tableData = data?.data || [];

  const tasksInProcess = findTasksInProcess(tableData);

  const isTableDataLoading = !silentUpdate && isTableLoading;

  useEffect(() => {
    if (!data || !tasksInProcess.length || isUninitialized) {
      return;
    }

    const interval = setInterval(refetchData, REFETCH_DATA_INTERVAL);

    // TODO fix this rule
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [data, refetchData, isUninitialized, tasksInProcess.length]);

  const additionalActions = [
    {
      component: (
        <RefreshButton
          onRefresh={fetchData}
          disabled={isTableLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
  ];

  return (
    <Table<TReports>
      title="CRM Reports"
      className="ReportsTable"
      titleSize={1}
      columns={columns}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
      isLoading={isTableDataLoading}
      filtersExpanded
      isFlexLayout
      disableSortBy
      manualSortBy
      hasFilters
      filteringProps={filteringProps}
      data={tableData}
      tableId={tableId}
      hasPagination
      showTableInfo
      saveColumnOrder
      serverPaginationProps={serverPaginationProps}
      isHiddenColumnSelect
      saveViewParamsAfterLeave
      pageSizes={[20, 50, 100]}
      additionalActions={additionalActions}
    />
  );
};
