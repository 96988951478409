import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  calculateCountOfPages,
  IconButton,
  IOnFetchArguments,
  Table,
  TAdditionalFilter,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useLazyExportCsvQuery,
  useLazyGetDataChangeRequestsQuery,
} from '~/api/dataChangeRequests';
import { TDataChangeRequests } from '~/api/dataChangeRequests/dataChangeRequests.types';
import { RefreshButton } from '~/components/RefreshButton';
import { PATHS } from '~/router';
import {
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
  getTableId,
} from '~/utils/table';

import {
  DISPLAYED_COLUMNS_KEYS,
  getColumns,
} from './DataChangeRequests.constants';

export const DataChangeRequests: FC = () => {
  const navigate = useNavigate();

  const [fetchDataChangeRequests] = useLazyGetDataChangeRequestsQuery();
  const [triggerExportCsv] = useLazyExportCsvQuery();

  const tableId = getTableId('data-change-requests');

  const getDataChangeRequests = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params);
      const filterParams = getFilterParams(params);

      const response = await fetchDataChangeRequests({
        ...paginationParams,
        ...filterParams,
      });

      return response.data;
    },
    [],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: {
        onFetch: getDataChangeRequests,
      },
      filters: {
        getDefaultFilters: () => {
          return { not_approved: true };
        },
      },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
    }),
    [getDataChangeRequests],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
  } = useTableData(tableDataArgs);

  const total = data?.iTotalDisplayRecords || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () => [
      {
        Header: 'New only',
        accessor: 'not_approved',
        type: 'checkbox',
        onFilter: setFilter,
        onRemove: removeFilter,
      },
    ],
    [removeFilter, setFilter],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters],
  );

  const columns = getColumns();

  const displayedColumnKeys = useMemo(() => DISPLAYED_COLUMNS_KEYS, []);

  const handleExportCsv = async () => {
    await triggerExportCsv({
      ...filters,
    });
  };

  const handleRowClick = useCallback(
    ({ id }: TDataChangeRequests) => {
      navigate(`${PATHS.DATA_CHANGE_REQUESTS}/${id}`);
    },
    [navigate],
  );

  const additionalActions = [
    {
      component: (
        <>
          <RefreshButton
            onRefresh={fetchData}
            disabled={isLoading}
            iconColor="secondary"
            title="Refresh table data"
          />
          <IconButton
            onClick={handleExportCsv}
            iconSize={24}
            iconName="ExportIcon"
            iconColor="secondary"
            title="Export requests to csv"
            data-test-id="data-change-requests__button--export-csv"
            className="DataChangeRequestsExportCsv"
          />
        </>
      ),
    },
  ];

  return (
    <Table<TDataChangeRequests>
      title="Data Change Requests"
      className="DataChangeRequestsTable"
      titleSize={1}
      columns={columns}
      displayedColumnKeys={displayedColumnKeys}
      isLoading={isLoading}
      filtersExpanded
      isFlexLayout
      disableSortBy
      manualSortBy
      hasFilters
      filteringProps={filterProps}
      data={data?.data || []}
      tableId={getTableId('data-change-requests')}
      handleRowClick={handleRowClick}
      hasPagination
      showTableInfo
      saveColumnOrder
      serverPaginationProps={serverPaginationProps}
      isHiddenColumnSelect
      saveViewParamsAfterLeave
      additionalActions={additionalActions}
    />
  );
};
