import { ChangeEvent, FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from 'react-ui-kit-exante';

import {
  DEPOSIT_STATUSES,
  useCreateCommentMutation,
  useDeleteDepositMutation,
  useGetGlobalContextQuery,
  useTransitionDepositMutation,
} from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import {
  mapCommentType,
  mapContentType,
} from '~/modules/comments/components/Comments/Comments.constants';
import { PATHS } from '~/router';

import { DepositFormContext } from '../DepositForm/contexts/DepositFormContext';

import { ON_HOLD_COMMENT_ERROR } from './DepositHeader.constants';
import {
  StyledDepositEntryActions,
  StyledCommentInput,
} from './DepositHeader.styled';
import { TDepositHeaderProps } from './DepositHeader.types';

export const DepositHeader: FC<TDepositHeaderProps> = ({ deposit }) => {
  const navigate = useNavigate();
  const { setIsEdit } = useContext(DepositFormContext);
  const { data: globalContext } = useGetGlobalContextQuery();
  const [onCommentCreate, commentCreateState] = useCreateCommentMutation();

  const [onDeleteDeposit, deleteState] = useDeleteDepositMutation();
  const [onTransitionDeposit, transitionState] = useTransitionDepositMutation();

  const [holdReason, setHoldReason] = useState('');
  const [reasonError, setReasonError] = useState('');

  const availableActions = deposit?.actions || [];

  const handleChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setReasonError(event.target.value ? '' : ON_HOLD_COMMENT_ERROR);
    setHoldReason(event.target.value);
  };

  const closeOnHoldHandler = () => {
    setHoldReason('');
    setReasonError('');
  };

  const onCloseEntry = () => {
    navigate(PATHS.DEPOSITS);
  };

  const onReject = () => {
    setIsEdit(false);
    onTransitionDeposit({
      depositId: deposit.id,
      status: DEPOSIT_STATUSES.REJECTED,
    });
  };

  const onAddComment = async () => {
    const contentType =
      globalContext?.content_type?.[mapContentType.deposits] || 0;

    const response = await onCommentCreate({
      content: holdReason,
      answered: false,
      content_type: contentType,
      hidden: false,
      is_system: false,
      kind: mapCommentType.deposits,
      object_id: deposit.id,
      removed: false,
    });

    if (!('error' in response)) {
      setHoldReason('');
      setReasonError('');
    }
  };

  const onHold = async () => {
    if (!holdReason) {
      setReasonError(ON_HOLD_COMMENT_ERROR);
      return;
    }
    setIsEdit(false);
    const response = await onTransitionDeposit({
      depositId: deposit.id,
      status: DEPOSIT_STATUSES.ONHOLD,
    });
    if (!('error' in response)) {
      onAddComment();
    }
  };

  const onBookFunds = () => {
    setIsEdit(false);
    onTransitionDeposit({
      depositId: deposit.id,
      status: DEPOSIT_STATUSES.BOOKED,
    });
  };

  const onMakeActive = () => {
    setIsEdit(false);
    onTransitionDeposit({
      depositId: deposit.id,
      status: DEPOSIT_STATUSES.ACTIVE,
    });
  };

  const onDelete = async () => {
    setIsEdit(false);
    const response = await onDeleteDeposit({ depositId: deposit.id });
    if (!('error' in response)) {
      if (response.data) {
        onCloseEntry();
      }
    }
  };

  const isLoading = deleteState.isLoading || transitionState.isLoading;

  const bookFundsBtn = (disabled: boolean) => (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Confirm"
      onConfirm={onBookFunds}
      title="Do you want to book funds in BO?"
      disabled={isLoading}
    >
      <IconButton
        iconName="CircleCheckIcon"
        iconColor="action"
        label="Book Funds"
        iconSize={32}
        disabled={isLoading || disabled}
      />
    </ActionWithConfirmation>
  );

  const onHoldBtn = (disabled: boolean) => (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      isInvalid={!holdReason}
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="On hold"
      onConfirm={onHold}
      closeHandler={closeOnHoldHandler}
      title="On hold"
      disabled={isLoading}
      content={
        <StyledCommentInput
          className="CommentInput"
          label="Comment"
          onChange={handleChangeComment}
          value={holdReason}
          error={!!reasonError}
          message={reasonError}
          disabled={commentCreateState.isLoading}
          multiline
        />
      }
    >
      <IconButton
        iconName="SuspendIcon"
        iconColor="warning"
        label="On hold"
        iconSize={32}
        disabled={isLoading || disabled || commentCreateState.isLoading}
      />
    </ActionWithConfirmation>
  );

  const rejectBtn = (disabled: boolean) => (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Reject"
      onConfirm={onReject}
      title="Do you want to reject the deposit?"
      disabled={isLoading}
    >
      <IconButton
        iconName="CircleCloseIcon"
        iconColor="radical"
        label="Reject"
        iconSize={32}
        disabled={isLoading || disabled}
      />
    </ActionWithConfirmation>
  );

  const makeActiveBtn = (disabled: boolean) => (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Confirm"
      onConfirm={onMakeActive}
      title="Do you want to make active the deposit?"
      disabled={isLoading}
    >
      <IconButton
        iconName="CircleCheckIcon"
        iconColor="action"
        label="Make Active"
        iconSize={32}
        disabled={isLoading || disabled}
      />
    </ActionWithConfirmation>
  );

  const deleteBtn = (disabled: boolean) => (
    <ActionWithConfirmation
      withCloseAfterConfirmation
      placement="bottom"
      cancelButtonNameKey="Cancel"
      confirmButtonNameKey="Delete"
      onConfirm={onDelete}
      title="Do you want to delete the deposit?"
      disabled={isLoading}
    >
      <IconButton
        iconName="DeleteIcon"
        iconSize={32}
        iconColor="secondary"
        disabled={isLoading || disabled}
      />
    </ActionWithConfirmation>
  );

  const actions = (
    <StyledDepositEntryActions className="DepositEntryActions">
      {availableActions?.map((action) => {
        switch (action.key) {
          case DEPOSIT_STATUSES.BOOKED: {
            return bookFundsBtn(action.disabled);
          }
          case DEPOSIT_STATUSES.ONHOLD: {
            return onHoldBtn(action.disabled);
          }
          case DEPOSIT_STATUSES.ACTIVE: {
            return makeActiveBtn(action.disabled);
          }
          case DEPOSIT_STATUSES.REJECTED: {
            return rejectBtn(action.disabled);
          }
          case 0: {
            return deleteBtn(action.disabled);
          }

          // [STATUSES.CHECKS_REQUIRED]: 'Save added checks', todo action for create deposit
          default:
            return '';
        }
      })}
    </StyledDepositEntryActions>
  );

  return (
    <EntryScreenHeader
      title={deposit?.username}
      onClose={onCloseEntry}
      actions={[actions]}
    />
  );
};
