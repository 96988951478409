import { createApi } from '@reduxjs/toolkit/query/react';
import { ISelectOption, Notification } from 'react-ui-kit-exante';

import { TDefaultPaginationResponse, TDefaultParams } from '~/types/api';
import { TTableFilters } from '~/types/table';
import { baseQueryHandler } from '~/utils/apiRequest';
import { getFormData } from '~/utils/formData';

import { DEPOSIT_STATUSES } from './deposits.constants';
import {
  TOptionsResponse,
  TDepositInfo,
  TUpdateDepositInfoParams,
  TUpload,
  TGetHiddenFieldsParams,
  TPostDepositConfirmParams,
} from './deposits.types';

export const depositsApi = createApi({
  reducerPath: 'depositsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'Deposits',
    'DepositsTableFilters',
    'Deposit',
    'CountryAOptions',
    'CcyOptions',
    'HiddenFields',
  ],
  endpoints: (builder) => ({
    getDeposits: builder.query<
      TDefaultPaginationResponse<TDepositInfo>,
      TDefaultParams
    >({
      query: (params) => ({
        url: '/rest/deposit-info-tab/',
        params,
      }),
      providesTags: ['Deposits'],
    }),
    getDepositTableFilters: builder.query<TTableFilters, void>({
      query: () => ({
        url: '/rest/deposit-filters/?table=deposits',
      }),
      providesTags: ['DepositsTableFilters'],
    }),

    getDeposit: builder.query<TDepositInfo, { id: string }>({
      query: ({ id }) => ({
        url: `/rest/deposit-info-tab/${id}/`,
      }),
      providesTags: ['Deposit'],
    }),

    uploadDocuments: builder.mutation<TUpload, { id: number; file: File }>({
      query: ({ id, file }) => {
        const formData = getFormData([['deposit_info', String(id)]]);
        formData.data.append('file', file);

        return {
          url: `/rest/deposit-info-upload/`,
          method: 'POST',
          ...formData,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'File successfully uploaded',
          });
        } catch (e) {
          Notification.error({
            title: 'File uploading error ',
          });
        }
      },
    }),

    deleteDocument: builder.mutation<
      void,
      { depositId: number; fileId: string }
    >({
      query: ({ depositId, fileId }) => ({
        url: `/rest/deposit-info-upload/${fileId}/?deposit_info=${depositId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'File successfully deleted',
          });
        } catch (e) {
          Notification.error({
            title: 'File deleting error ',
          });
        }
      },
      invalidatesTags: ['Deposit'],
    }),

    updateDepositInfo: builder.mutation<
      TDepositInfo,
      { depositId: number; updateInfo: TUpdateDepositInfoParams }
    >({
      query: ({ depositId, updateInfo }) => ({
        url: `/rest/deposit-info-tab/${depositId}/`,
        method: 'PATCH',
        data: updateInfo,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Deposit successfully updated',
          });
        } catch (e) {
          Notification.error({
            title: 'Deposit updating error ',
          });
        }
      },
      invalidatesTags: ['Deposit'],
    }),
    deleteDeposit: builder.mutation<boolean, { depositId: number }>({
      query: ({ depositId }) => ({
        url: `/rest/deposit-info-tab/${depositId}/`,
        method: 'DELETE',
      }),
      transformResponse: () => {
        return true;
      },
      transformErrorResponse: () => {
        return false;
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Deposit successfully deleted',
          });
        } catch (e) {
          Notification.error({
            title: 'Deposit deleting error ',
          });
        }
      },
    }),
    transitionDeposit: builder.mutation<
      boolean,
      {
        depositId: number;
        status: (typeof DEPOSIT_STATUSES)[keyof typeof DEPOSIT_STATUSES];
      }
    >({
      query: ({ depositId, status }) => ({
        url: `/rest/deposit-info/${depositId}/transition/`,
        method: 'POST',
        data: {
          status,
        },
      }),
      transformResponse: () => {
        return true;
      },
      transformErrorResponse: () => {
        return false;
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Deposit successfully transitioned',
          });
        } catch (e) {
          Notification.error({
            title: 'Deposit transition error ',
          });
        }
      },
      invalidatesTags: ['Deposit'],
    }),
    getCountryA: builder.query<ISelectOption[], void>({
      query: () => ({
        url: `/rest/deposit-info-fields/country_a/`,
      }),
      transformResponse(response: TOptionsResponse) {
        return response.choices?.map((item) => ({
          label: item.text,
          value: item.id,
        }));
      },
      providesTags: ['CountryAOptions'],
    }),
    getCcy: builder.query<ISelectOption[], void>({
      query: () => ({
        url: `/rest/deposit-info-fields/ccy/`,
      }),
      transformResponse(response: TOptionsResponse) {
        return response.choices?.map((item) => ({
          label: item.text,
          value: item.id,
        }));
      },
      providesTags: ['CcyOptions'],
    }),
    getGetHiddenFields: builder.query<string[], TGetHiddenFieldsParams>({
      query: ({ type }) => ({
        url: `/rest/deposit-info-hidden-fields/${type}/?return_keys=True`,
      }),
      providesTags: ['HiddenFields'],
    }),

    getDepositDownloadUploadedFile: builder.query<
      { url: string },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/rest/deposit-info-upload/${id}/document/`,
      }),
    }),
    postDepositConfirm: builder.mutation<void, TPostDepositConfirmParams>({
      query: ({ id, confirm }) => {
        return {
          url: `/rest/deposit-info/${id}/confirm/`,
          method: 'POST',
          data: { confirm },
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Successfully',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Deposit'],
    }),
  }),
});

export const {
  useLazyGetDepositsQuery,
  useGetDepositTableFiltersQuery,
  useGetDepositQuery,
  useUploadDocumentsMutation,
  useDeleteDocumentMutation,
  useUpdateDepositInfoMutation,
  useLazyGetCountryAQuery,
  useLazyGetCcyQuery,
  useLazyGetGetHiddenFieldsQuery,
  useLazyGetDepositDownloadUploadedFileQuery,
  usePostDepositConfirmMutation,
  useDeleteDepositMutation,
  useTransitionDepositMutation,
} = depositsApi;
