import { FC } from 'react';

import { StyledLinksCell, StyledLinksCellLink } from './LinksCell.styled';
import { TLinksCellProps } from './LinksCell.types';

export const LinksCell: FC<TLinksCellProps> = ({ links }) => {
  return (
    <StyledLinksCell className="RequestsLinksCell">
      {links.map((link, index) => (
        <li key={index}>
          <StyledLinksCellLink
            className="RequestsLinksCellLink"
            href={link.url}
            target="_blank"
            rel="noreferrer"
          >
            {link.name}
          </StyledLinksCellLink>
        </li>
      ))}
    </StyledLinksCell>
  );
};
