import { IColumn } from 'react-ui-kit-exante';

import { TWithdrawalsData } from '~/api/withdrawals/withdrawals.types';

import { StatusCell } from './components/StatusCell';

export const DISPLAYED_COLUMNS_KEYS = [
  'username',
  'client_id',
  'account',
  'ip',
  'ip_country',
  'datetime',
  'status',
  'responsible_title',
  'changed',
  'amount_currency',
  'currency',
  'eur_amount_repr',
  'confirm_type',
  'legal_entity',
  'assigned_to',
  'type',
];

export const getColumns = (): IColumn<TWithdrawalsData>[] => {
  return [
    {
      id: 'id',
      Header: 'Id',
    },
    {
      id: 'username',
      Header: 'User',
    },
    {
      id: 'client_id',
      Header: 'Client Id',
    },
    {
      id: 'account',
      Header: 'Account Id',
    },
    {
      id: 'ip',
      Header: 'Ip',
    },
    {
      id: 'ip_country',
      Header: 'Ip Country',
    },
    {
      id: 'datetime',
      Header: 'Created',
      formatting: 'dateUTC',
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: StatusCell,
    },
    {
      id: 'responsible_title',
      Header: 'Responsible',
      disableSortBy: true,
    },
    {
      id: 'changed',
      Header: 'Changed',
      formatting: 'dateUTC',
    },
    {
      id: 'amount_currency',
      Header: 'Amount Currency',
    },
    {
      id: 'currency',
      Header: 'Currency',
    },
    {
      id: 'eur_amount_repr',
      Header: 'Eur Equivalent',
    },
    {
      id: 'confirm_type',
      Header: 'Confirm Type',
    },
    {
      id: 'legal_entity',
      Header: 'Legal Entity',
    },
    {
      id: 'assigned_to',
      Header: 'Assigned To',
    },
    {
      id: 'type',
      Header: 'Type',
    },
    {
      id: 'commission',
      Header: 'Commission',
    },
    {
      id: 'account_nav',
      Header: 'Account Nav',
      disableSortBy: true,
    },
    {
      id: 'is_withdrawn',
      Header: 'Is Withdrawn',
    },
    {
      id: 'is_crypto_withdrawn',
      Header: 'Is Crypto Withdrawn',
    },
    {
      id: 'all_funds',
      Header: 'All Funds',
    },
    {
      id: 'is_card_withdrawn',
      Header: 'Card Withdrawn',
    },
    {
      id: 'source',
      Header: 'Source',
    },
    {
      id: 'bank_country',
      Header: 'Bank Country',
    },
    {
      id: 'value_date',
      Header: 'Value Date',
    },
    {
      id: 'bank_to_transfer',
      Header: 'Bank To Transfer',
    },
    {
      id: 'account_to_transfer',
      Header: 'Account To Transfer',
    },
    {
      id: 'transfer_status',
      Header: 'Transfer Status',
    },
    {
      id: 'iban',
      Header: 'Iban',
    },
    {
      id: 'account_number',
      Header: 'Account Number',
    },
    {
      id: 'swift',
      Header: 'Swift',
    },
    {
      id: 'bank_name',
      Header: 'Bank Name',
    },
    {
      id: 'exante_account',
      Header: 'Exante Account',
    },
    {
      id: 'withdrawal_securities__currency',
      Header: 'Securities Currency',
    },
    {
      id: 'withdrawal_securities__security_type',
      Header: 'Securities Security Type',
    },
    {
      id: 'withdrawal_securities__security_name',
      Header: 'Securities Security Name',
    },
    {
      id: 'withdrawal_securities__isin_code',
      Header: 'Securities Isin Code',
    },
    {
      id: ' withdrawal_securities__exchange',
      Header: 'Securities Exchange',
    },
    {
      id: 'withdrawal_securities__quantity',
      Header: 'Securities Quantity',
    },
    {
      id: 'withdrawal_securities__price',
      Header: 'Securities Price',
    },
    {
      id: 'withdrawal_securities__commission',
      Header: 'Securities Commission',
    },
    {
      id: 'withdrawal_securities__settlement_date',
      Header: 'Securities Settlement Date',
    },
    {
      id: 'withdrawal_securities__trade_date',
      Header: 'Securities Trade Date',
    },
    {
      id: 'withdrawal_securities__custodian',
      Header: 'Securities Custodian',
    },
    {
      id: 'sepa_swift',
      Header: 'Sepa Swift',
    },
    {
      id: 'comply_radar_id',
      Header: 'Comply Radar Id',
    },
    {
      id: 'deposit_group',
      Header: 'Deposit Group',
    },
  ].map((item) => ({ ...item, accessor: item.id, disableFilters: true }));
};

export const fieldsParams = {
  columns:
    'username,client_id,account,ip,ip_country,datetime,status,responsible_title,changed,amount_currency,currency,eur_amount_repr,commission,account_nav,confirm_type,legal_entity,assigned_to,type,is_withdrawn,is_crypto_withdrawn,all_funds,is_card_withdrawn,source,value_date,bank_country,bank_to_transfer,account_to_transfer,transfer_status,iban,account_number,swift,bank_name,exante_account,withdrawal_securities__currency,withdrawal_securities__security_type,withdrawal_securities__security_name,withdrawal_securities__isin_code,withdrawal_securities__exchange,withdrawal_securities__quantity,withdrawal_securities__price,withdrawal_securities__commission,withdrawal_securities__settlement_date,withdrawal_securities__trade_date,withdrawal_securities__custodian,sepa_swift,comply_radar_id,deposit_group',
};

export const WITHDRAWALS_DATE_FIELDS = [
  'field_datetime',
  'field_value_date',
  'field_changed',
  'field_withdrawal_securities__trade_date',
  'field_withdrawal_securities__settlement_date',
];
