import { FC, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useLazyGetReportLinkQuery, useLazyGetReportQuery } from '~/api';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import { linkAndClick } from '~/utils/linkAndClick';

import { EReportStatus } from '../../Reports.types';

import {
  StyledReportsActionsCell,
  StyledReportsActionsLoader,
} from './ReportsActionsCell.styled';
import { TReportsActionsProps } from './ReportsActionsCell.types';

export const ReportsActionsCell: FC<TReportsActionsProps> = ({
  status,
  id,
  refetchData,
}) => {
  const [isRestarting, setIsRestarting] = useState<boolean>(false);

  const [fetchReport] = useLazyGetReportQuery();
  const [getDownloadLink] = useLazyGetReportLinkQuery();

  const restartReport = async () => {
    setIsRestarting(true);

    await fetchReport(String(id));

    refetchData();
  };

  const downloadReport = async () => {
    const { data } = await getDownloadLink(String(id));

    if (data) {
      linkAndClick(data.url);
    }
  };

  return (
    <StyledReportsActionsCell className="ReportsActionsCell">
      {isRestarting && <StyledReportsActionsLoader isInner size={24} />}
      {!isRestarting &&
        (status === EReportStatus.Done || status === EReportStatus.Failure) && (
          <IconButton
            iconName="RotateCcwIcon"
            iconColor="secondary"
            iconSize={24}
            title="Restart"
            onClick={restartReport}
          />
        )}
      {!isRestarting && status === EReportStatus.Done && (
        <IconButton
          iconName="DownloadIcon"
          className="ReportsActionsLink"
          iconColor="secondary"
          title="Download"
          onClick={downloadReport}
        />
      )}
      {!isRestarting && status === EReportStatus.Failure
        ? DEFAULT_EMPTY_PLACEHOLDER
        : ''}
    </StyledReportsActionsCell>
  );
};
