import { isEmpty } from 'lodash';
import { FC, useContext, useEffect, useRef } from 'react';

import { getFormField } from 'utils/getFormField';
import { TWithdrawalsData } from '~/api/withdrawals/withdrawals.types';
import { ValueRow } from '~/components/ValueRow';
import { autoScrollToSomething } from '~/utils/autoScrollToSomething';

import { WithdrawalFormContext } from '../../context';
import { getWithdrawalFields } from '../../context/helpers';

import {
  StyledEditableWithdrawalWrapper,
  StyledEditableWithdrawalField,
} from './EditableWithdrawalForm.styled';

export const EditableWithdrawalForm: FC<{
  isEditMode: boolean;
  withdrawal: TWithdrawalsData;
}> = ({ withdrawal, isEditMode }) => {
  const { messageForBankToTransfer, isCanChangeAmount, isCanChange } =
    useContext(WithdrawalFormContext);

  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditMode) {
      autoScrollToSomething(formRef);
    }
  }, [isEditMode]);

  return (
    <StyledEditableWithdrawalWrapper
      ref={formRef}
      className="EditableWithdrawalWrapper"
    >
      {getWithdrawalFields(
        withdrawal,
        isCanChangeAmount,
        isCanChange,
        !!messageForBankToTransfer,
      ).map(
        ({
          name,
          title,
          type,
          formatter,
          optionsConfig,
          editable,
          disabled,
          width,
        }) => {
          const value = withdrawal[name as keyof TWithdrawalsData] as
            | string
            | number
            | boolean;

          if (isEmpty(value) && typeof value !== 'boolean' && !isEditMode) {
            return null;
          }

          if (!isEditMode || !editable) {
            return (
              <ValueRow
                key={name}
                label={title}
                value={formatter ? formatter(value as string) : value}
              />
            );
          }

          return (
            <ValueRow
              key={name}
              label={title}
              valueNode={
                <StyledEditableWithdrawalField className="EditableWithdrawalField">
                  {getFormField({
                    type,
                    name,
                    width,
                    options: optionsConfig?.options,
                    disabled,
                    message:
                      name === 'bank_to_transfer'
                        ? messageForBankToTransfer
                        : '',
                  })}
                </StyledEditableWithdrawalField>
              }
            />
          );
        },
      )}
    </StyledEditableWithdrawalWrapper>
  );
};
