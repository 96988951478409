import { createElement, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Input, Loader, Table } from 'react-ui-kit-exante';

import {
  useApproveDataChangeRequestMutation,
  useGetDataChangeRequestQuery,
  useGetPermissionsQuery,
  useRejectDataChangeRequestMutation,
} from '~/api';
import { EDataChangeRequestStatuses } from '~/api/dataChangeRequests/dataChangeRequests.types';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import {
  EntryColumn,
  EntryScreenWrapper,
  EntrySection,
} from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow/ValueRow';
import { PATHS } from '~/router';
import { TParams } from '~/router/router.types';
import { getTableId } from '~/utils/table';

import { EntryScreenHeader } from '../../components/EntryScreen/EntryScreenHeader';

import { getColumns } from './DataChangeRequestsEntry.constants';
import {
  mapIcons,
  transformDataChangeRequestData,
} from './DataChangeRequestsEntry.helpers';
import {
  StyledDCRequestDetails,
  StyledDCRequestEmailWithLink,
  StyledDCRequestEntryPage,
  StyledDCRequestEntryRejectConfirm,
  StyledDCRequestIconStatus,
  StyledDCRequestStatus,
  StyledDCRequestStatusText,
  StyledDCRequestTextStatus,
  StyledDCRequestsEntryActions,
} from './DataChangeRequestsEntry.styled';
import { TDataChangeRequestLog } from './DataChangeRequestsEntry.types';

export const DataChangeRequestsEntry = () => {
  const navigate = useNavigate();
  const { id } = useParams<TParams>();
  const { data: permissions } = useGetPermissionsQuery();

  const [rejectReason, setRejectReason] = useState('');
  const [isRejectReasonEmpty, setIsRejectReasonEmpty] = useState(false);

  const [approveRequest] = useApproveDataChangeRequestMutation();
  const [rejectRequest] = useRejectDataChangeRequestMutation();

  const { data: request, isLoading } = useGetDataChangeRequestQuery({
    id: String(id),
  });

  const {
    status: requestStatus,
    datetime_change: datetimeChange,
    changed_by_full_name: changedByFullName,
    changed_by_email: changedByEmail,
    data_list: dataList,
    application_full_name: applicationFullName,
    application_email: applicationEmail,
    ip,
    ip_country: ipCountry,
    comment,
    created_at: createdAt,
    application_link: applicationLink,
  } = request || {};

  const hasPermission = permissions?.clientsarea?.manage_data_change_requests;

  const iconStatus = (
    <StyledDCRequestIconStatus
      className="DCRequestIconStatus"
      status={requestStatus}
    >
      {requestStatus && mapIcons[requestStatus]
        ? createElement(mapIcons[requestStatus])
        : ''}
    </StyledDCRequestIconStatus>
  );

  const formattedRequestStatus =
    requestStatus &&
    requestStatus.charAt(0).toUpperCase() + requestStatus.slice(1);

  const textStatus = (
    <StyledDCRequestTextStatus className="Status" status={requestStatus}>
      {formattedRequestStatus} {datetimeChange}
    </StyledDCRequestTextStatus>
  );

  const responsible = String(`${changedByFullName} ${changedByEmail}`);

  const emailWithLink = (
    <StyledDCRequestEmailWithLink
      className="DCRequestEmailWithLink"
      href={applicationLink}
      target="_blank"
      rel="noreferrer"
    >
      {applicationEmail}
    </StyledDCRequestEmailWithLink>
  );

  const isNew = requestStatus === EDataChangeRequestStatuses.New;

  const tableData = transformDataChangeRequestData(dataList);

  const columns = getColumns(isNew);

  const rejectConfirmContent = (
    <StyledDCRequestEntryRejectConfirm className="DCRequestEntryRejectConfirm">
      <h3>Describe the reason of rejection</h3>
      <p>Dear client</p>
      <p>
        Please imply the following changes so that we could proceed with your
        request:
      </p>
      <Input
        placeholder="This field is required"
        multiline
        fullWidth
        onChange={(e) => setRejectReason(e.target.value)}
        className="DCRequestEntryRejectConfirmField"
        error={isRejectReasonEmpty}
        required
      />
      <p>Please send a new profile change request.</p>
    </StyledDCRequestEntryRejectConfirm>
  );

  const handleApprove = async () => {
    if (id) {
      const response = await approveRequest({ id });

      if ('error' in response) {
        return;
      }

      navigate(PATHS.DATA_CHANGE_REQUESTS);
    }
  };

  const handleReject = async () => {
    if (!rejectReason) {
      setIsRejectReasonEmpty(true);
    } else if (id) {
      const response = await rejectRequest({ id, rejectReason });

      if ('error' in response) {
        return;
      }

      navigate(PATHS.DATA_CHANGE_REQUESTS);
      setIsRejectReasonEmpty(false);
    }
  };

  if (isLoading || !request) {
    return (
      <EntryScreenWrapper>
        <Loader size={32} isCentered />
      </EntryScreenWrapper>
    );
  }

  const actions = (
    <StyledDCRequestsEntryActions className="DataChangeRequestsEntryActions">
      <IconButton
        onClick={handleApprove}
        iconSize={24}
        iconName="CircleCheckIcon"
        iconColor="action"
        label="Approve"
        data-test-id="data-change-request__button--approve"
        className="DataChangeRequestApproveButton"
      />
      <ActionWithConfirmation
        onConfirm={handleReject}
        title="Reject data change request"
        content={rejectConfirmContent}
        placement="bottom"
        confirmButtonNameKey="Confirm"
        cancelButtonNameKey="Cancel"
      >
        <IconButton
          iconSize={24}
          iconName="CircleCloseIcon"
          iconColor="radical"
          label="Reject"
          data-test-id="data-change-request__button--reject"
          className="DataChangeRequestRejectButton"
        />
      </ActionWithConfirmation>
    </StyledDCRequestsEntryActions>
  );

  return (
    <EntryScreenWrapper>
      <EntryScreenHeader
        title={applicationFullName}
        onClose={() => navigate(PATHS.DATA_CHANGE_REQUESTS)}
        actions={[isNew && hasPermission && actions]}
      />
      {!isNew && (
        <StyledDCRequestStatus className="DCRequestStatus">
          {iconStatus}
          <StyledDCRequestStatusText className="DCRequestStatusText">
            <p>{textStatus}</p>
            <p>{responsible}</p>
          </StyledDCRequestStatusText>
        </StyledDCRequestStatus>
      )}
      <StyledDCRequestEntryPage>
        <EntryColumn>
          <EntrySection title="Request details">
            <StyledDCRequestDetails className="DCRequestDetails">
              <ValueRow label="User" value={applicationFullName} />
              {/* todo On email should be a link to the client card */}
              <ValueRow label="Email" valueNode={emailWithLink} />
              <ValueRow label="IP" value={`${ip} / ${ipCountry}`} />
              <ValueRow label="Request Time" value={createdAt} />
              {comment && <ValueRow label="Comment" value={comment} />}
            </StyledDCRequestDetails>
          </EntrySection>
        </EntryColumn>
        <EntryColumn>
          <EntrySection title="Request changes">
            <Table<TDataChangeRequestLog>
              className="DataChangeRequestsLogTable"
              columns={columns}
              isLoading={isLoading}
              isFlexLayout
              disableSortBy
              data={tableData || []}
              tableId={getTableId('data-change-requests-log')}
              isHiddenColumnSelect
            />
          </EntrySection>
        </EntryColumn>
      </StyledDCRequestEntryPage>
    </EntryScreenWrapper>
  );
};
