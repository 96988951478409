import {
  blockNonNativeProps,
  styled,
  ArrowRightSmallIcon,
} from 'react-ui-kit-exante';

import { TStyledArrow, TStyledHiddenChildren } from './Accordion.types';

export const StyledAccordionWrapper = styled('div')`
  width: 100%;

  button {
    border-top: none;
  }
`;

export const StyleAccordionButton = styled('button')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 0;
  cursor: ${({ isDisabled }: { isDisabled: boolean | undefined }) =>
    isDisabled ? 'default' : 'pointer'};
  color: ${({ theme }) => theme?.color.typo.primary};
  background-color: ${({ theme }) => theme?.color.bg.primary};
  border-top: ${({ theme }) => `1px solid ${theme?.color?.dropdown?.border}`};
`;

export const StyleAccordionArrow = styled(ArrowRightSmallIcon, {
  shouldForwardProp: blockNonNativeProps('isRight'),
})<TStyledArrow>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.color.icon.ghost : theme.color.icon.secondary};

  svg {
    transform: ${({ isRight }) => (isRight ? 'initial' : 'rotate(90deg)')};
  }
`;

export const StyleAccordionHiddenChildren = styled('div', {
  shouldForwardProp: blockNonNativeProps('isHidden'),
})<TStyledHiddenChildren>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
`;
