export const PERMISSIONS_COLUMNS = [
  { Header: 'Permission', accessor: 'name', width: '300' },
  {
    Header: 'Admin',
    accessor: 'admin',
  },
  { Header: 'Support', accessor: 'support' },
  { Header: 'Manager', accessor: 'manager' },
  {
    Header: 'Onboarding',
    accessor: 'onboarding',
  },
  {
    Header: 'Sales QA',
    accessor: 'sales_qa',
  },
  {
    Header: 'Backoffice',
    accessor: 'backoffice',
  },
  {
    Header: 'Marketing',
    accessor: 'marketing',
  },
];

export const PAGE_SIZE = 20;
