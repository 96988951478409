import { styled } from 'react-ui-kit-exante';

export const StyledLinksCell = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;

  li + li {
    margin-top: 8px;
  }
`;

export const StyledLinksCellLink = styled('a')`
  color: ${({ theme }) => theme?.color.typo.action};

  &:hover,
  &:visited {
    color: ${({ theme }) => theme?.color.typo.promo};
  }
`;
