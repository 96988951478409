import { Loader, styled } from 'react-ui-kit-exante';

export const StyledReportsActionsCell = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledReportsActionsLoader = styled(Loader)`
  & > div {
    position: relative;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
