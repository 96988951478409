import { FC, useEffect } from 'react';
import { FileUpload, TFile, UploadStatus } from 'react-ui-kit-exante';

import {
  useDeleteDocumentMutation,
  useGetPermissionsQuery,
  useUpdateDepositInfoMutation,
  useUploadDocumentsMutation,
  useLazyGetDepositDownloadUploadedFileQuery,
} from '~/api';
import { ACCEPT_FILE_LIST } from '~/constants/files';

import { TDocumentsProps } from './Documents.types';

export const Documents: FC<TDocumentsProps> = ({ deposit, isLoading }) => {
  const [onUploadDocuments, stateUpload] = useUploadDocumentsMutation();
  const [onDeleteDocument, stateDelete] = useDeleteDocumentMutation();
  const [onUpdateDepositInfo, stateDeposit] = useUpdateDepositInfoMutation();
  const { data: permissions } = useGetPermissionsQuery();
  const [downloadUploadedFile, downloadState] =
    useLazyGetDepositDownloadUploadedFileQuery();

  const isUninitializedActionsState =
    !stateUpload.isUninitialized || !stateDelete.isUninitialized;

  const isShowLoading =
    (isLoading ||
      stateUpload.isLoading ||
      stateDelete.isLoading ||
      downloadState.isLoading ||
      stateDeposit.isLoading ||
      downloadState.isFetching) &&
    isUninitializedActionsState;

  const resetActionsState = () => {
    if (isUninitializedActionsState && !isShowLoading) {
      stateUpload.reset();
      stateDelete.reset();
    }
  };

  useEffect(() => {
    resetActionsState();
  }, [stateUpload.isUninitialized, stateDelete.isUninitialized, isShowLoading]);

  const filesInitial: TFile[] = deposit.uploads.map((file) => ({
    ...file,
    id: String(file.id),
    removable: true,
    size: 0,
    uploadStatus: UploadStatus.SUCCESS,
  }));
  const handleFileAppend = async (payload: File[]) => {
    const uploads = await Promise.all(
      payload.map(async (file) => {
        const uploaded = await onUploadDocuments({ id: deposit.id, file });
        if (!('error' in uploaded)) {
          return uploaded.data.id;
        }
        return null;
      }),
    );

    const succeedUploadsIds = uploads.filter((f) => f !== null);

    if (succeedUploadsIds.length) {
      const ids = [
        ...(succeedUploadsIds as number[]),
        ...(deposit?.uploads.map((f) => f.id) || []),
      ];

      // todo должно работать без этого https://exante.slack.com/archives/G0DBBB998/p1710780712011029 https://jira.exante.eu/browse/CRM-3234
      onUpdateDepositInfo({
        updateInfo: { uploads_ids: ids },
        depositId: deposit.id,
      });
    }
  };

  const handleDelete = async (id: string) => {
    await onDeleteDocument({ depositId: deposit.id, fileId: id });
  };

  const getFile = async (id: string) => {
    const { data: dataUrl } = await downloadUploadedFile({ id });

    const url = dataUrl?.url;

    if (url) {
      window.open(url, '_blank');
    }
  };

  const hasEditPerms = Boolean(permissions?.deposit_info?.change_depositinfo);

  return (
    <div>
      {isShowLoading && <FileUpload loading />}
      {!isShowLoading && (
        <FileUpload
          accept={ACCEPT_FILE_LIST}
          onUpload={handleFileAppend}
          filesInitial={filesInitial}
          showFileSize={false}
          onDelete={handleDelete}
          withDeleteConfirmation
          disabled={!hasEditPerms}
          onDownload={getFile}
        />
      )}
    </div>
  );
};
